import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "../styles/home-page.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faTag,
  faCheckCircle,
  faHandshake,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";

const HomePageContent = ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query HomePageContent {
          datoCmsHome {
            logo {
              url
            }
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            architecturalImage {
              fluid(maxWidth: 800, imgixParams: {fm: "jpg", auto: "compress"}) {
                src
              }
            }
            bespokeImage {
              fluid(maxWidth: 800, imgixParams: {fm: "jpg", auto: "compress"}) {
                src
              }
            }
            floodImage {
              fluid(maxWidth: 800, imgixParams: {fm: "jpg", auto: "compress"}) {
                src
              }
            }
            residential2ndImage {
              fluid(maxWidth: 800, imgixParams: {fm: "jpg", auto: "compress"}) {
                src
              }
            }
            commercial2ndImage {
              fluid(maxWidth: 800, imgixParams: {fm: "jpg", auto: "compress"}) {
                src
              }
            }
            processImage {
              fluid(maxWidth: 800, imgixParams: {fm: "jpg", auto: "compress"}) {
                src
              }
            }
          }
        }
      `}
      render={(data) => (
      <section>
        <div className="why-best">
          <h1>Why We're The Best</h1>
          <div className="why-best-wrapper">
            <div className="best-container">
              <FontAwesomeIcon icon={faClock} />
              <p>Fast on the Job, Available 7 Days</p>
            </div>
            <div className="best-container">
              <FontAwesomeIcon icon={faTag} />
              <p>Fair & Affordable Pricing Rates</p>
            </div>
            <div className="best-container">
              <FontAwesomeIcon icon={faCheckCircle} />
              <p>Trusted, Licensed & Fully Insured</p>
            </div>
            <div className="best-container">
              <FontAwesomeIcon icon={faHandshake} />
              <p>Locally Owned & Operated</p>
            </div>
          </div>
        </div>

      <div class="fields">
        <div class="field">
          <div class="textBox">

            <h1>Bespoke Renovations</h1>

            <p>Lets us take the worry out of your next renovation. Talk to us today about our end to end project services and management. We design, build, deliver and maintain all types of renovations from a new bay window to a new kitchen or bathroom.</p>
            <Link to="/contact">
            Request Quote<FontAwesomeIcon icon={faChevronRight} />
            </Link>

          </div>

          <div class="imgBox">

          <Img fluid={data.datoCmsHome.bespokeImage.fluid} />

          </div>
        </div>

        <div class="field">

          <div class="textBox">

            <h1>Architectural Builds</h1>

            <p>One off builds are exciting and challenging. Our experience ensures we get your dream right first time, every time. Talk to us today about involvement within your design stage to ensure a comprehensive build package without any hidden surprises or costs.</p>

            <Link to="/contact">
            Request Quote<FontAwesomeIcon icon={faChevronRight} />
            </Link>
            

            </div>
            <div class="imgBox">

          <Img fluid={data.datoCmsHome.architecturalImage.fluid} />

          </div>
          </div>
          
          <div class="field">
            <div class="textBox">

              <h1>Flood Remediation</h1>

              <p>Restumping, repointing, replastering, repairing. Let us remediate the damage and manage the insurance program on your behalf.</p>

              <Link to="/contact">
            Request Quote<FontAwesomeIcon icon={faChevronRight} />
            </Link>

            </div>

            <div class="imgBox">

            <Img fluid={data.datoCmsHome.floodImage.fluid} />

            </div>
        </div>
        <div class="field">
          <div class="textBox">

            <h1>Residential</h1>

            <p>All aspects of residential building works undertaken. We understand residential jobs are all different. We are the builder who takes the time to discuss your unique requirements and we listen.</p>

            <Link to="/contact">
            Request Quote<FontAwesomeIcon icon={faChevronRight} />
            </Link>

          </div>

          <div class="imgBox">

          <Img fluid={data.datoCmsHome.residential2ndImage.fluid} />

          </div>
        </div>
        <div class="field">
          <div class="textBox">

            <h1>Commercial</h1>

            <p>Small to medium commercial projects by tender response. Please talk to us if you would like to resource or tender as part of the Smets Building team.</p>

            <Link to="/contact">
            Request Quote<FontAwesomeIcon icon={faChevronRight} />
            </Link>

          </div>

          <div class="imgBox">

          <Img fluid={data.datoCmsHome.commercial2ndImage.fluid} />

          </div>
        </div>
        <div class="field">
          <div class="textBox">

            <h1>Our Process</h1>

            <p>We listen, we care, we deliver. First time builds to experienced developers, we step you through the process using our years of industry knowledge and experience. We're here to take the stress and complication out of your next project.</p>

            <Link to="/contact">
            Request Quote<FontAwesomeIcon icon={faChevronRight} />
            </Link>

          </div>

          <div class="imgBox">

          <Img fluid={data.datoCmsHome.processImage.fluid} />

          </div>
        </div>
        </div>
      </section>
        
      )}
    />
  );
};

export default HomePageContent;
