import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "../styles/blocks/four-card.sass";

const FourCard = ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query FourCard {
          datoCmsHome {
            servicesImage {
              fluid(
                maxWidth: 1000
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                src
              }
            }
            servicesImage2 {
              fluid(
                maxWidth: 1000
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                src
              }
            }
            servicesImage3 {
              fluid(
                maxWidth: 1000
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                src
              }
            }
            servicesImage4 {
              fluid(
                maxWidth: 1000
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                src
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="four-card">
          <figure className="card-container">
            <div className="card-image">
              <Img fluid={data.datoCmsHome.servicesImage.fluid} />
            </div>
            <figcaption className="card-caption">
              <div className="card-title">
                <h4>New Builds</h4>
              </div>
              <p className="card-description">
                Architectural builds, custom designs and kit home building
                solutions.Sets offers a full turn-key service from design to
                delivery.
              </p>
            </figcaption>
          </figure>
          <figure className="card-container">
            <div className="card-image">
              <Img fluid={data.datoCmsHome.servicesImage2.fluid} />
            </div>
            <figcaption className="card-caption">
              <div className="card-title">
                <h4>Renovations</h4>
              </div>
              <p className="card-description">
                Sympathetic renovations suited to the style of your existing
                home, or a modern, light filled, architectural design to wow
                you. We work with you to foster your creation.
              </p>
            </figcaption>
          </figure>
          <figure className="card-container">
            <div className="card-image">
              <Img fluid={data.datoCmsHome.servicesImage3.fluid} />
            </div>
            <figcaption className="card-caption">
              <div className="card-title">
                <h4>Residential</h4>
              </div>
              <p className="card-description">
                Smets Building are residential building contractors able to
                bring a wealth of building experience and industry contacts to
                your next project.
              </p>
            </figcaption>
          </figure>
          <figure className="card-container">
            <div className="card-image">
              <Img fluid={data.datoCmsHome.servicesImage4.fluid} />
            </div>
            <figcaption className="card-caption">
              <div className="card-title">
                <h4>Commercial</h4>
              </div>
              <p className="card-description">
                Smets Building contract small to medium commercial builds and
                fit-outs under a variety of contracting structures.
              </p>
            </figcaption>
          </figure>
        </div>
      )}
    />
  );
};
export default FourCard;
