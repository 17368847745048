import React from "react";
import { StaticQuery, graphql } from "gatsby";
// import Img from "gatsby-image";
import "../styles/blocks/paragraph.sass";

const Paragraph = ({ data }) => {
    return (
      <StaticQuery
        query={graphql`
          query Paragraph {
                datoCmsHome {
                  introText
                  introTitle
                }
              }
        `}

        render={(data) => (
            <div className="paragraph-container">
            <div className="paragraph">
                <h1>{data.datoCmsHome.introTitle}</h1>
                <p>{data.datoCmsHome.introText}</p>
            </div>
            </div>
          )}
        />
      );
    };
    export default Paragraph;