import React from "react";
import { graphql } from "gatsby";
// import { HelmetDatoCms } from "gatsby-source-datocms";
import Layout from "../components/layout";
import HomeSlick from "../components/home-slick";
import FourCard from "../components/four-card";
import Footer from "../components/footer";
import HomePageContent from "../components/home-page-content";
import Paragraph from "../components/paragraph";
const IndexPage = ({ data }) => (
  <div>
    <Layout></Layout>
    <HomeSlick></HomeSlick>
    <FourCard></FourCard>
    <Paragraph></Paragraph>
    <HomePageContent></HomePageContent>
    <Footer></Footer>
  </div>
);

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    allDatoCmsWork(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          coverImage {
            fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;
