import React from "react";
import Slider from "react-slick";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Img from "gatsby-image";
import "../styles/home-slider.sass";
// import Img from "gatsby-image";
// import PropTypes from "prop-types";
// import { Link } from "gatsby";
// import { HelmetDatoCms } from "gatsby-source-datocms";

const HomeSlick = ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query HomeSlickQuery {
          datoCmsHome {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            sliderLogo {
              fluid(
                maxWidth: 900
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                ...GatsbyDatoCmsFluid
                src
              }
            }
            homeGallery {
              title
              fluid(
                maxWidth: 2000
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                src
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="homeGallery">
          <div className="homeTitle">
            <div className="sliderLogo">
              <Img fluid={data.datoCmsHome.sliderLogo.fluid} />
            </div>
            <h1>Tasmanian Building and Carpentry Services</h1>
            <button>
              <Link to="/projects">View Projects</Link>
            </button>
          </div>
          <Slider
            dots={true}
            autoplay={true}
            infinite={true}
            slidesToShow={1}
            arrows={true}
          >
            {data.datoCmsHome.homeGallery.map(({ fluid }) => (
              <img
                alt={data.datoCmsHome.homeGallery.title}
                key={fluid.src}
                src={fluid.src}
              />
            ))}
          </Slider>
        </div>
      )}
    />
  );
};
export default HomeSlick;
